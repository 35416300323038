function loadFile(url, callback) {
    try {
        if (url !== undefined && url !== null && url !== '') {
            var jsExpr = new RegExp("js$", "i");
            var cssExpr = new RegExp("css$", "i");
            var jsonExpr = new RegExp("json$", "i");

            var testurl = url.split('?')[0];
            var type = null;
            if (jsExpr.test(testurl) || jsonExpr.test(testurl)) {
                type = 'text/javascript';
            } else if (cssExpr.test(testurl)) {
                type = 'text/css';
            }

            var tag = null;
            switch (type) {
            case 'text/javascript':
                tag = document.createElement('script');
                tag.type = type;
                tag.src = url;
                tag.async = true;
                break;
            case 'text/css':
                tag = document.createElement('link');
                tag.rel = 'stylesheet';
                tag.type = type;
                tag.href = url;
                break;
            }
            if (callback != null) {
                if (tag.readyState) { // IE, include IE9
                    tag.onreadystatechange = function() {
                        if (tag.readyState == "loaded" || tag.readyState == "complete") {
                            tag.onreadystatechange = null;
                            callback();
                        }
                    };
                } else {
                    tag.onload = function() { // Other browsers
                        callback();
                    };
                }
            }
            var a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(tag, a);
        }
    } catch (ex) {
        console.error("Fail to load file :- " + url, ex);
    }
}