RegExp.escape = function (text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};
Array.prototype.unique = function () {
    var a = this.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i].value.toLowerCase() === a[j].value)
                a.splice(j--, 1);
        }
    }
    return a;
};
function RenderAutoSuggestion(inputterm) {
    var $elem;
    $(inputterm).autocomplete("destroy");
    let token = Dell.OnlineUtils.AppSettings.Unified.CoveoToken != undefined ? Dell.OnlineUtils.AppSettings.Unified.CoveoToken : "";
    $elem = $(inputterm).autocomplete({
        minLength: Dell.OnlineUtils.AppSettings.Unified.MastheadUnifiedProductNameAutoSuggestMinLength,
        dataType: "json",
        source: function(request, response) {
            var combinedResult = new Array();
            Dell.Types.SupportHomepage.prototype.ProductSupportData = [];
            Dell.Search.AutosuggestHtml.InputTextboxId = inputterm;
            try {
                request.term = request.term.replace(/</g, ' ').replace(/>/g, ' ').trim();
                currSearchString = request.term.toLowerCase();
                var productSearchString = currSearchString;
                //if dont get result for prev string & prev is substring of curr, dont check local storage
                if (mastHeadPrevSearchInput != "" && currSearchString.indexOf(mastHeadPrevSearchInput) != -1) {
                    productSearchString = ""; //to skip local storage operation
                } else {
                    mastHeadPrevSearchInput = currSearchString;
                    productSearchString = currSearchString;
                }
                // trim needed only for coveo input, not for dell: cause exact match with product name
                //todo: change coveoData.coveoToken to Dell.OnlineUtils.AppSettings.Token for other applications
                $.when($.Coveo.search(currSearchString.trim(), token),
                    $.DellProductsJsStorage.search(productSearchString)).done(
                        function(coveoResult, dellResult) {
                            console.log("dellResult count:" + dellResult.length);
                            console.log("coveoResult count:" + coveoResult.length);

                            //if we find search term in product list, continue looking in product list -> so clear prev search strin value
                            if (dellResult != null && dellResult.length > 0) {
                                Dell.Search.AutosuggestHtml.HasDellData = true;
                                mastHeadPrevSearchInput = "";

                                //if exact match set ProductSupportData for 'search button click event' to directly redirect to product support page.
                                if (currSearchString == dellResult[0].value.toLowerCase()) {
                                    Dell.Types.SupportHomepage.prototype.ProductSupportData =
                                        { Title: dellResult[0].value, uri: dellResult[0].url };
                                }
                            } else
                                Dell.Search.AutosuggestHtml.HasDellData = false;

                            Dell.Search.AutosuggestHtml.HasCoveoData =
                                (coveoResult != null && coveoResult.length > 0);

                            combinedResult = dellResult.concat(coveoResult);
                            return response(combinedResult.unique());
                        });

            } catch (error) {
                mastHeadPrevSearchInput = "";
            }

        },
        open: function() {
            $("ul.ui-menu").width(document.querySelector(inputterm).clientWidth);
        }
    }).off("focus").on('focus',
        function(event) {
            var self = this;
            $(self).autocomplete("search", this.value);
        });

    var elemAutocomplete = $elem.data("ui-autocomplete") || $elem.data("autocomplete");
    if (elemAutocomplete) {
        elemAutocomplete._renderItem = function (ul, item) {
            this.term = this.term.replace(/[%,*]/gi, "");
            var spacecount = this.term.split(' ').length;
            var newText = item.value;
            if (spacecount > 1) {
                for (var i = 0; i < spacecount; i++) {
                    if (this.term.split(' ')[i] != undefined && this.term.split(' ')[i] != null)
                        newText = newText.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + this.term.split(' ')[i].replace(/([\^\$\(\)\[\]\{\}\*\.\+\?\|\\])/gi, "\\$1") + ")(?![^<>]*>)(?![^&;]+;)", "gi"), Dell.Search.AutosuggestHtml.HightlightSpan);
                }
            }
            else {
                newText = String(newText).replace(new RegExp(this.term, "gi"), Dell.Search.AutosuggestHtml.HightlightSpan);
            }
            var anchor;
            if (item.isDellProduct) {
                if (Dell.OnlineUtils.AppSettings.Unified.DisplayUnifiedAutosuggestImage !== undefined && Dell.OnlineUtils.AppSettings.Unified.DisplayUnifiedAutosuggestImage) {
                    var image = "<img class='auto-a-image' loading='lazy' src=" + item.imageUrl.replace(/ /g, "%20") + ">";
                    //need both onclick and href here. 
                    anchor = $("<a class='auto-a'>" + image + newText + "</a>")
                        .attr("href", item.url).on("click", function (event) { event.preventDefault(); redirectToProductSupport(this); });
                }
                else
                    anchor = $("<a class='auto-a'>" + newText + "</a>").attr("href", item.url).on("click", function (event) { event.preventDefault(); redirectToProductSupport(this); });
            }
            else
                anchor = $("<a class='auto-a'>" + newText + "</a>").attr("href", item.url).on("click", function (event) { event.preventDefault(); redirectToSearch(this, event); });
            return $(Dell.Search.AutosuggestHtml.MenuItem).on({ mouseenter: function () { coveoHover(this, inputterm); }, mouseleave: function () { coveoHoverOff(inputterm) } }).append(anchor).appendTo(ul);
        };

        //if (Dell.OnlineUtils.AppSettings.Unified.IsTwoSectionUnifiedAutosuggestEnabled !== undefined && Dell.OnlineUtils.AppSettings.Unified.IsTwoSectionUnifiedAutosuggestEnabled)
        InitializeAutoSuggestWithTwoSections(elemAutocomplete);

        elemAutocomplete.menu.options.selected = function (event, ui) {
            //do nothing needed to suppress inbuilt selected event
        };

        elemAutocomplete._keyEvent = function (keyEvent, event) {
            // suppress up and down arrow keys. 
        }
    }
}

function InitializeAutoSuggestWithTwoSections(elemAutocomplete) {
    elemAutocomplete._renderMenu = function (ul, items) {
        var that = this;
        var dellProductList = $(Dell.Search.AutosuggestHtml.OrderedListDell);
        var covSrchResList = $(Dell.Search.AutosuggestHtml.OrderedListCoveo);
        $(ul).addClass('coveo-ui-autocomplete-max-height');
        $.each(items, function (index, item) {
            if (item.isDellProduct)
                that._renderItem(dellProductList, item);
            else
                that._renderItem(covSrchResList, item);
        });

        if (Dell.Search.AutosuggestHtml.HasDellData) {
            ul.append($("<li class='ui-autocomplete-category'><h6>" + Dell.OnlineUtils.AppSettings.Unified.UnifiedDellResultTitle + "</h6></li>"));
            ul.append(dellProductList);
            ul.append($(Dell.Search.AutosuggestHtml.HorizontalLine));
        }
        if (Dell.Search.AutosuggestHtml.HasCoveoData)
            ul.append($("<li class='ui-autocomplete-category'><h6><svg class='coveo-dti'><svg id='dt-search' viewBox='0 0 32 32'><path d='M30.304 29.005l-9.363-9.365c1.562-1.873 2.505-4.28 2.505-6.904 0-5.956-4.845-10.801-10.801-10.801s-10.803 4.845-10.803 10.801 4.845 10.801 10.801 10.801c2.656 0 5.086-0.968 6.968-2.562l9.361 9.361 1.331-1.331zM12.644 21.655c-4.917 0-8.919-4-8.919-8.919s4.002-8.919 8.919-8.919 8.919 4 8.919 8.919-4.002 8.919-8.919 8.919z'></path></svg></svg>" + Dell.OnlineUtils.AppSettings.Unified.UnifiedCoveoResultTitle + "</h6></li>"));

        //bottom section
        var filter = window.location.hash;
        if (filter.indexOf("&q") > 0) {
            filter = filter.slice(filter.indexOf("sort"), filter.indexOf("&q"));
        } else {
            filter = filter.slice(filter.indexOf("sort"), filter.length);
        }
        var covSearchUrl = Dell.OnlineUtils.AppSettings.Unified.UnifiedSearchRedirectUrl + '#q=' + encodeURIComponent(currSearchString) + '&' + filter;
        var anchor = $("<hr style='margin-top:0px; margin-bottom:0px;'><a class='BottomLink'>" + Dell.OnlineUtils.AppSettings.Unified.UnifiedViewAllFor + " \"<b>" + currSearchString.trim() + "</b>\"&nbsp;<svg class='coveo-dti'><svg id='dt-arrow-right' viewBox='0 0 32 32'><path d='M16.407 3.976l-1.237 1.419 11.096 9.673h-24.399v1.882h24.399l-11.096 9.675 1.237 1.417 13.803-12.034z'></path></svg></svg></a>").attr("href", covSearchUrl).on("click", function (event) { event.preventDefault(); redirectToSearch(this, event); });
        covSrchResList.append($(Dell.Search.AutosuggestHtml.BottomSectionLi).append(anchor));
        ul.append(covSrchResList);
    }
}

function redirectToProductSupport(element) {
    var redirectionType = 'product' + ':' + element.innerText;
    dellmetricsTrack_prop20_format("111.300.200.002", redirectionType);
    if (element.href != undefined && element.href != "")
        location.href = element.href;
}

function redirectToSearch(element, event) {
    event.preventDefault();
    $(Dell.Search.AutosuggestHtml.InputTextboxId).autocomplete("close");
    window.ViewAllClick = true;
    if (element.href != undefined && element.href != "" && $(Dell.Search.AutosuggestHtml.InputTextboxId).val().trim().length > 0) {
        location.href = element.href.trim();
    }
    event.stopPropagation();
}

function coveoHover(element, inputterm) {
    try {

        document.getElementsByClassName("ui-autocomplete-input")[0].value = element.innerText;
    }
    catch (error) { }
}

function coveoHoverOff(inputterm) {
    try {

        document.getElementsByClassName("ui-autocomplete-input")[0].value = currSearchString;
    }
    catch (error) { }
}

function DestroyAutoSuggest(inputterm) {
    $(inputterm).autocomplete("close");
}

var mastHeadPrevSearchInput = "";
var currSearchString = "";

Dell = window.Dell || {};
Dell.Search = Dell.Search || {};

Dell.Search.AutosuggestHtml = { //can not be const cause value changes
    HorizontalLine: "<li><hr style='margin-top:0px; margin-bottom:0px;'></li>",
    OrderedListDell: "<ol class='auto-ol' style='max-height: 105px;' ></ol>",
    OrderedListCoveo: "<ol class='auto-ol' style='max-height: 300px; overflow-y: hidden' ></ol>",
    BottomSectionLi: "<li class='BottomSection'></li>",
    HightlightSpan: "<span class='ui-state-highlight'>$&</span>",
    MenuItem: "<li></li>",
    InputTextboxId: "",
    HasDellData: false,
    HasCoveoData: false
};

String.prototype.format = function () {
    var a = this;
    for (var k in arguments) {
        a = a.replace(new RegExp("\\{" + k + "\\}", 'g'), arguments[k]);
    }
    return a;
}
