Dell = window.Dell || {};
Dell.Types = Dell.Types || {};
Dell.OnlineUtils = Dell.OnlineUtils || {};
Dell.OnlineUtils.AppSettings = Dell.OnlineUtils.AppSettings || {};

var searchApiRoute = 'https://www.dell.com/support/search/api/supportsearchapi';
var authenticateApiRoute = 'https://www.dell.com/support/search/api/supportsearchapi/isloggedin';
var searchApiPromise = $.Deferred();

Dell.Types.SupportHomepage = function() {
    var divObjL = document.getElementById("divMastheadLayoutJSVariable");
    Dell.OnlineUtils.AppSettings.Search = {};
    if (divObjL !== null && divObjL !== undefined) {
        Dell.OnlineUtils.AppSettings.SignInUrl = divObjL.getAttribute("data-accountSignInLink");
        Dell.OnlineUtils.AppSettings.MasthHead = divObjL.getAttribute("data-mh");
    }

    //Constants 
    this.Constants = {};
    this.Constants.Selectors = {};
    this.Constants.CONSUMER_SEGMENT = 'dhs';
    //Lwp
    this.language = Dell.OnlineUtils.Lwp.language;
    this.country = Dell.OnlineUtils.Lwp.country;
    this.customerset = Dell.OnlineUtils.Lwp.customerset;
    this.segment = Dell.OnlineUtils.Lwp.segment;

    //Constants - Commonly Used Selectors
    this.Constants.Selectors.SEARCH_INPUT = "#search-input";
    this.Constants.Selectors.SEARCH_FORM = "#search-form";
    this.Constants.Selectors.SEARCH_SELECTION = "#search-selection";

    this.Constants.Selectors.SEARCH_INPUT_MOB = "#search-input-mob";
    this.Constants.Selectors.SEARCH_FORM_MOB = "#search-form-mob";
    this.Constants.Selectors.SEARCH_SELECTION_MOB = "#search-selection-mob";

    this.Constants.Selectors.UNIFIED_MASTHEAD = "#um-search-input";
    this.Urls = {};
    return this;
};

//-----------------------------------
//initializeUX
//Initializes SupportHome Visual Elements (should be run on DOM Ready)
//-----------------------------------
Dell.Types.SupportHomepage.prototype.initializeUX = function() {
    setTimeout(function() {
        //GUI Fixup
        $('html,body').animate({ scrollTop: 0 }, 0);
        //Add supportpage-initialized marker to DOM
        $('#site-wrapper').addClass('supportpage-initialized');

        // sign in
        Dell.Types.SupportHomepage.prototype.signInMenu();

        // side-moblie-menu
        $(document).on('click',
            '#mobile-new-menu',
            function(event) {
                if ($('.site-canvas').hasClass('site-canvas-mob'))
                    $('.site-canvas').removeClass('site-canvas-mob');

                $('html').toggleClass('openNav');
                hideSearchFlyout();
                hideSignInFlyout();
                event.stopPropagation();
            });

        $(document).on('click',
            "#mobile-new-search-link",
            function() {
                if (!$(".auth-page").hasClass('open')) {
                    $('.site-canvas').toggleClass('site-canvas-mob');
                }
                hideSignInFlyout();
                $("#mobile-new-search-link").toggleClass("open-search");
                $(".search-container").toggleClass("open-search");
            });


        //for touch devices
        $(document).on('touchend',
            '#mobile-new-menu',
            function(event) {
                event.preventDefault();
                if ($('.site-canvas').hasClass('site-canvas-mob'))
                    $('.site-canvas').removeClass('site-canvas-mob');

                $('html').toggleClass('openNav');
                hideSearchFlyout();
                hideSignInFlyout();
                event.stopImmediatePropagation();
            });

        var hideSearchFlyout = function() {
            $("#mobile-new-search-link").removeClass("open-search");
            $(".search-container").removeClass("open-search");
        };

        var hideSignInFlyout = function() {
            $("#signin-link").parent().parent().removeClass("open");
        };
        // ----------------------------------------------------------------------------------------------------
        // Start Country Selector
        // ----------------------------------------------------------------------------------------------------
        $('#countryselector').on('shown.bs.dropdown',
            function() {
                var $this = $(this);
                $(document).keypress(function(e) {
                    var key = String.fromCharCode(e.which);
                    $this.find(".dropdown-menu a").each(function(idx, item) {
                        if ($(item).text().trim().charAt(0).toLowerCase() === key) {
                            $(item).focus();
                            return false;
                        }
                    });
                });
                $("#countryselector").hasClass('dropdown')
                    ? $("#countryselector").removeClass('dropdown').addClass('dropup')
                    : $("#countryselector").removeClass('dropup').addClass('dropdown');
            });

        // unbind key event when dropdown is hidden
        $('#countryselector').on('hide.bs.dropdown',
            function() {
                $("#countryselector").hasClass('dropup')
                    ? $("#countryselector").removeClass('dropup').addClass('dropdown')
                    : $("#countryselector").removeClass('dropdown').addClass('dropup');
            });
        // ----------------------------------------------------------------------------------------------------
        // End Country Selector
        // ----------------------------------------------------------------------------------------------------
    }, 0);
};


//-----------------------------------
//Sign Menu
//
Dell.Types.SupportHomepage.prototype.signInMenu = function () {
    try {
        if ($(window).width() < 768) {
            // Utility section dropdown links for mobile
            $(document).off('mouseenter', ".masthead__utilities_user,.sign-in-content-wrapper");
            $(document).off('mouseleave', ".masthead__utilities_user,.sign-in-content-wrapper");
            $(document).off('click', ".masthead__utilities_user").on('click', ".masthead__utilities_user", function () {
                if (!$("#mobile-new-search-link").hasClass('open-search')) {
                    $('.site-canvas').toggleClass('site-canvas-mob');
                }
                var selfParent = $(this);
                if (selfParent.hasClass('open'))
                    selfParent.removeClass('open');
                else
                    selfParent.addClass('open');

                $("#mobile-new-search-link").removeClass("open-search");
                $(".search-container").removeClass("open-search");
            });
            var left = 0;
            if ($('.masthead__utilities_cart').length > 0) {
                left = $('.masthead__utilities_cart').position().left;
            } else if ($('.masthead__utilities_menucollapse').length > 0) {
                left = $('.masthead__utilities_menucollapse').position().left;
            }
            $('.sign-in-content-wrapper').css({
                "width": $('#site-wrapper').width(),
                "left": "auto",
                "right": left - $('#site-wrapper').width()
            });
            $('.countrylist').css('left', 0);
        } else {
            $(document).off('click', ".masthead__utilities_user");
            $(document).on('mouseenter', ".masthead__utilities_user,.sign-in-content-wrapper", function () {
                if (!$("#mobile-new-search-link").hasClass('open-search')) {
                    $('.site-canvas').toggleClass('site-canvas-mob');
                }
                $(this).addClass('open');
            });
            $(document).on('mouseleave', ".masthead__utilities_user,.sign-in-content-wrapper", function () {
                if (!$("#mobile-new-search-link").hasClass('open-search')) {
                    $('.site-canvas').toggleClass('site-canvas-mob');
                }
                $(this).removeClass('open');
            });
            $('.sign-in-content-wrapper').css({
                "width": "320px",
                "left": "auto",
                "right": "0px"
            });
            $('.countrylist').css('left', 'auto');
        }
    }
    catch (ex) {
        console.error("Error in Sign In Menu" + ex);
    }
};


//-----------------------------------
//initialize Unified Search
//-----------------------------------
var UnifiedMHId = "";
window.addEventListener('mastheadReady.um', function (e) {
    UnifiedMHId = e.detail.searchInputId;
    var searchClear = e.detail.searchClear;
    var searchSubmitButton = e.detail.searchSubmitButton;
    var seachCancelButton = e.detail.seachCancelButton;
    $.when(searchApiPromise.promise())
        .done(function (apiresponse) {
            Dell.Types.SupportHomepage.prototype.handleSearchAutoComplete("", UnifiedMHId);
            Dell.Types.SupportHomepage.prototype.handleSearch("", UnifiedMHId, undefined, "");
            $(UnifiedMHId).on("keypress", function (e) {
                Dell.Types.SupportHomepage.prototype.handleSearch("", UnifiedMHId, e, "");
            });
            $(searchClear).on('click', function () {
                $(UnifiedMHId).val("");
            });
            $(searchSubmitButton).on('click', function (e) {
                Dell.Types.SupportHomepage.prototype.handleSearch("", UnifiedMHId, e, "");
            });
            $(seachCancelButton).on('click', function () {
                $(UnifiedMHId).val("");
            });
        }).fail(function () {
        }).always(function () {
        });
});

window.addEventListener("UnifiedSearchInitEvent", function (event) {
    var inputEntryId = event.detail.inputEntryId;
    var submitEntry = event.detail.submitEntry;
    if (inputEntryId != undefined && inputEntryId !== null && inputEntryId !== '') {
        Dell.Types.SupportHomepage.prototype.handleSearchAutoComplete('', "#" + inputEntryId);
        document.getElementById(inputEntryId).addEventListener("keyup", function (event) {
            Dell.Types.SupportHomepage.prototype.handleSearch("", "#" + inputEntryId, event, "");
        });
    }
    if (submitEntry != undefined && submitEntry !== null && submitEntry !== '') {
        document.getElementById(submitEntry).addEventListener("click", function (event) {
            Dell.Types.SupportHomepage.prototype.handleSearch("", "#" + inputEntryId, event, "");
        });
    }
});

Dell.Types.SupportHomepage.prototype.initializeUnifiedMastHead = function () {
    try {
        if (UnifiedMHId == null || UnifiedMHId === "") {
            Dell.Types.SupportHomepage.prototype.initializeSearch();
        }
    } catch (ex) {
        console.error("Unified MH is not present: " + ex);
        Dell.Types.SupportHomepage.prototype.initializeSearch();
    }
};

Dell.Types.SupportHomepage.prototype.initializeSearch = function () {
    Dell.Types.SupportHomepage.prototype.handleSearch(Dell.Supportpage.Constants.Selectors.SEARCH_FORM,
        Dell.Supportpage.Constants.Selectors.SEARCH_INPUT, undefined, "111.300.200.001");
    Dell.Types.SupportHomepage.prototype.handleSearchAutoComplete(
        Dell.Supportpage.Constants.Selectors.SEARCH_FORM,
        Dell.Supportpage.Constants.Selectors.SEARCH_INPUT, undefined, "111.300.200.001");
    //mobile view
    Dell.Types.SupportHomepage.prototype.handleSearch(Dell.Supportpage.Constants.Selectors.SEARCH_FORM_MOB,
        Dell.Supportpage.Constants.Selectors.SEARCH_INPUT_MOB, undefined, "111.300.200.001");
    Dell.Types.SupportHomepage.prototype.handleSearchAutoComplete(
        Dell.Supportpage.Constants.Selectors.SEARCH_FORM_MOB,
        Dell.Supportpage.Constants.Selectors.SEARCH_INPUT_MOB, undefined, "111.300.200.001");
};

Dell.Types.SupportHomepage.prototype.mastheadSubmitOmnitureTracking = function(searchTerm, ominiturevalue) {
    try {
        var searchDomain = "support";
        ominiturevalue = (ominiturevalue == undefined || ominiturevalue === "" || ominiturevalue === null)
            ? "111.300.200.001"
            : ominiturevalue;
        Dell = window.Dell || {};
        Dell.Metrics = Dell.Metrics || {};
        Dell.Metrics.sc = Dell.Metrics.sc || {};
        Dell.Metrics.sc.coveovisitor = Dell.Metrics.sc.coveovisitor || {};
        Dell.Metrics.sc.searchTerm = searchTerm;
        Dell.Metrics.sc.supportappindex = ominiturevalue;
        Dell.Metrics.sc.cms = searchDomain !== "support" ? "Fuel" : "Coveo";
        Dell.Metrics.sc.pagename = Dell.Metrics.sc.country +
            "|" +
            Dell.Metrics.sc.language +
            "|" +
            Dell.Metrics.sc.segment +
            "|" +
            Dell.Metrics.sc.customerset +
            "|coveo|searchresults";
        Dell.Metrics.sc.pagename += "[tab=" + searchDomain + "]";
        Dell.Metrics.sc.detailpagename = "";
        if (typeof (dellmetrics_pagenav) != 'undefined') {
            dellmetrics_pagenav(Dell.Metrics.sc.pagename);
        }
    } catch (e) {
        console.error("mastheadSubmitOmnitureTracking : " + e.message);
    }
};

//-----------------------------------
//handleSearch
//-----------------------------------
Dell.Types.SupportHomepage.prototype.handleSearch = function(searchForm, searchInput, event, omniturevalue) {
    if (searchForm != null && searchForm !== "") {
        $(searchForm).unbind('submit');
        $(searchForm).submit(function (event) {
            event.preventDefault();
            HandleSearchRequest(searchInput, omniturevalue);
        });
    } else if (event != undefined && (event.type === "click" || event.keyCode === 13)) {
        HandleSearchRequest(searchInput, omniturevalue);
    }
};

var regExpRemoveSpclCharsFromBothEnds = "[^\\p{L}0-9]";
var regExpGuid = new RegExp("^[0-9a-zA-Z]{8}[-]([0-9a-zA-Z]{4}[-]){3}[0-9a-zA-Z]{12}$", "i");
var regExpString7CharAlphanumeric = new RegExp("^(?=.{6,7}$)(?!dl)(?=.*[0-9])(?=.*[a-zA-Z]).*$", "i");
var regExpAllSpecialCharacters = new RegExp("[`!@#$%^&*()_+\\-=\\[\\]{};':\\\\|,.<>\\/?~]");
var serviceTagKeywords = "st,svc tag,service tag,svctag,servicetag";
var arrServiceTagKeywords = serviceTagKeywords.split(',');
var nullCacheParams = GetNullCahceRequestParams();
var regEx7DigitsAlphabets = new RegExp("[a-zA-Z]{8,}$", "i");
//If user enters string with special character, filter it as per the requirements
function getFilteredSearchString(searchText) {

    if (!searchText) { return searchText; }
    var filteredText = searchText.trim().toLowerCase();
    if (regExpAllSpecialCharacters.test(filteredText) || (arrServiceTagKeywords && checkSTKeywordAvailability(arrServiceTagKeywords, filteredText))) { // **STJSTIS07!@
        filteredText = getTextWithoutSpclChar(filteredText); //Remove special characters from begin and end of the string
        if (checkIfSearchTextIsGuid(filteredText) || CheckIfSearchTextMinWith2Hyphens(filteredText)) //if search text is a guid, redirect it to KBDoc page.
        {
            setTimeout(function () { window.location.href = nullCacheParams.RedirectUri }, nullCacheParams.IntervelValue);
            args.cancel = true;
            return false;
        }
        if (checkIfSearchTextIs7Alphanumeric(filteredText)) { return filteredText; }   //if searchText is 6 or 7 alphanumeric       

        var stringWithoutSearchTagKeywords = getStringWithoutSearchTagKeywords(filteredText);  //if searchText starts with st
        if (filteredText != stringWithoutSearchTagKeywords) { return stringWithoutSearchTagKeywords; }
    }
    return searchText.trim();
}
function checkSTKeywordAvailability(arrServiceTagKeywords, searchText) {
    return arrServiceTagKeywords.some(
        function (serviceTagKeyword) {
            return searchText.startsWith(serviceTagKeyword);
        });
}

// Remove special characters from both ends of the search text i.e from *(Audio-issue./) to Audio-issue
function getTextWithoutSpclChar(searchText) {
    var regExp = new RegExp('^' + regExpRemoveSpclCharsFromBothEnds + '+|' + regExpRemoveSpclCharsFromBothEnds + '+$', "gu");
    return searchText.replace(regExp, '').trim();
}
//check if search text is guid like 205dcc75-ff9c-4dbc-a27f-5f058ee30137
function checkIfSearchTextIsGuid(filteredText) {
    return regExpGuid.test(filteredText);
}
//check if search text is a 7 char alphanumeric e.g. XYZ1234
function checkIfSearchTextIs7Alphanumeric(filteredText) {
    return regExpString7CharAlphanumeric.test(filteredText);
}
//if string starts with st, remove st and special char e.g from "st=123xyzf" to "123xyzf"
function getStringWithoutSearchTagKeywords(filteredText) { //getServiceTag
    var originalText = filteredText;
    for (var i = 0, ln = arrServiceTagKeywords.length; i < ln; i++) {
        const string = '^' + arrServiceTagKeywords[i];
        const regExp = new RegExp(string);
        if (regExp.test(filteredText)) {
            filteredText = filteredText.replace(arrServiceTagKeywords[i], '');
            if (filteredText != "")
                filteredText = filteredText.substring(filteredText.match('[a-zA-Z0-9]').index);
            break;
        }
    }
    return regExpString7CharAlphanumeric.test(filteredText) ? filteredText : originalText;
};
//validate search text length is >=11 and it has more than two hyphens(-) Ex: mx-0c6r37-74262-07e-18au
function CheckIfSearchTextMinWith2Hyphens(filteredText) {
    if (filteredText.indexOf(" ") < 0 && filteredText.length >= 11 && filteredText.split('-').length - 1 > 2) {
        return true;
    }
    else {
        return false;
    }
}
function HandleSearchRequest(searchInput, ominiturevalue) {
    $(searchInput).autocomplete('close');
    var searchString = $(searchInput).val().replace(/</g, '').replace(/>/g, '').trim();
    var filteredSearchString = getFilteredSearchString(searchString);
    if (searchString.length < 1) {
        return;
    }
    Dell.Types.SupportHomepage.prototype.setSearchProgressAction(true, searchInput);
    if (Dell.Types.SupportHomepage.prototype.ProductSupportData.Title != undefined &&
        Dell.Types.SupportHomepage.prototype.ProductSupportData.Title.length > 0) {
        Dell.Types.SupportHomepage.prototype.redirectProductSupportPage();
    } else {
        var serviceTagRegExPattern = new RegExp(Dell.OnlineUtils.AppSettings.Unified.UnifiedValidateEntityCheckRegEx);
        var isValidForEntityCheck = serviceTagRegExPattern.test(filteredSearchString) && filteredSearchString.length > 4 && !regEx7DigitsAlphabets.test(filteredSearchString);
        if ((isValidForEntityCheck && Dell.OnlineUtils.AppSettings.Unified.UnifiedIsValidateEntityCheckEnabled)
            || (searchString && searchString.split('-').length - 1 > 0 && searchString.split('-')[1].length > 0 && checkIfSearchTextIs7Alphanumeric(searchString.split('-')[1]))) {
            Dell.Types.SupportHomepage.prototype.handleCoveoSearchQuery(searchInput, filteredSearchString);
        } else {
            Dell.Types.SupportHomepage.prototype.redirectToCoveoSearchPage(searchInput);
        }
    }

    Dell.Types.SupportHomepage.prototype.mastheadSubmitOmnitureTracking(searchString, ominiturevalue);
}

Dell.Types.SupportHomepage.prototype.getNewHash = function() {
    var hash = location.hash;
    hash = hash.replace("#", "").split("&");
    var newHash = "";
    if (hash !== undefined && hash != null && hash.length > 0) {
        hash.filter(function(val) {
            if (val.split("=")[1] !== undefined && val.split("=")[1] != null && val.split("=")[0].toLowerCase() !== "q")
                newHash += "&" + val.split("=")[0] + "=" + val.split("=")[1];
        });
    }
    return newHash;
};

//-----------------------------------
//executeSearch
//-----------------------------------
Dell.Types.SupportHomepage.prototype.executeSearch = function (searchForm) {
    $(searchForm).submit();
};

//-----------------------------------
//handleSearchAutoComplete
//-----------------------------------
Dell.Types.SupportHomepage.prototype.handleSearchAutoComplete = function (searchForm, searchInput) {
    window.DestroyAutoSuggest(searchInput);
    if (searchForm != null && searchForm !== "") {
        $(searchForm).attr('action', Dell.OnlineUtils.AppSettings.Unified.UnifiedSearchRedirectUrl);
    }
    Dell.Supportpage.coveoAuto(searchInput);
};

Dell.Types.SupportHomepage.prototype.dellSearchAuto = function (searchInput) {
    window.RenderAutoSuggestion(searchInput);
};

Dell.Types.SupportHomepage.prototype.coveoAuto = function ( searchInput) {
    window.RenderAutoSuggestion(searchInput);
};

// Dell.Supportpage Object Initialization ----------------------------------------------
Dell.Supportpage = Dell.Supportpage || new Dell.Types.SupportHomepage();

// UX Initializtion (after DOM Ready) ----------------------------------------------
$(function () {
    Dell.Supportpage.initializeUX();
});

$(function () {
    CheckAuthentication();
});

// Footer changes
var footerUrlLwp = function(country, lang, customerset) {
    var isFromSearch = typeof (handleSearchQs) !== 'undefined';
    var urlArray = (!isFromSearch)
        ? location.href.split('?')[0].split('/')
        : (location.href.indexOf('?') > -1
            ? location.href.split('?')[0].split('/')
            : location.href.split('#')[0].split('/'));

    if (window.countryLocalizedSoit && window.countryLocalizedSoit === true) {
        if (window.countryLocalizedSoitThreePath && window.countryLocalizedSoitThreePath === true) {
            urlArray = CountryClickEventBasedOnThreeSegmentedCultureUrl(country, lang, urlArray);
        } else {
            urlArray = CountryClickEventBasedOnCultureUrl(country, lang, urlArray);
        }
    } else {
        if (window.countryLocalizedSoitThreePath && window.countryLocalizedSoitThreePath === true) {
            urlArray = CountryClickEventBasedOnThreeSegmentedUrl(country, lang, customerset, urlArray);
        } else {
            urlArray = CountryClickEventBasedOnSegmentedUrl(country, lang, customerset, urlArray);
        }
    }

    var qStrings = "";
    if (location.search.length > 0) {
        $.each(location.search.split("&"),
            function(index, val) {
                if (index == 0)
                    val = val.substring(1, val.length);
                if (val.split("=")[0].toLowerCase() != "c" &&
                    val.split("=")[0].toLowerCase() != "l" &&
                    val.split("=")[0].toLowerCase() != "s" &&
                    val.split("=")[0].toLowerCase() != "cs" &&
                    val.split("=")[0].toLowerCase() != "sc") {
                    if (typeof (handleLangQs) !== 'undefined' && val.split("=")[0].toLowerCase() === "lang") {
                        // nothing here, just to avoid lang redirection for articles and other siblings 
                    } else {
                        qStrings += qStrings.length == 0
                            ? "?" + val.split("=")[0] + "=" + val.split("=")[1]
                            : "&" + val.split("=")[0] + "=" + val.split("=")[1];
                    }
                }
            });
    }
    var newUrl = urlArray.join("/") + (qStrings.length == 0 ? "" : qStrings);
    if (newUrl.indexOf('#') == -1) {
        var filterSearchCriteria = location.href.split('#');
        if (filterSearchCriteria.length > 1) {
            newUrl = newUrl + "#" + filterSearchCriteria[1];
        }
    }
    var currentUrl = newUrl;
    if (currentUrl.indexOf("lwp=rt", 0) < 0) {
        var splittedUrl = currentUrl.split('#');
        var beforeHash = splittedUrl[0];
        beforeHash += (beforeHash.indexOf("?", 0) > -1 ? "&" : "?") + "lwp=rt";
        currentUrl = beforeHash + (splittedUrl.length > 1 ? "#" + splittedUrl[1] : "");
    }
    setTimeout(function() { SecureRedirectUrl(currentUrl); }, 0);
};

function CountryClickEventBasedOnSegmentedUrl(country, lang, customerset, urlArray) {
    var tempcustomerSet;
    if (urlArray[3].match(new RegExp("support", "gi"))) {
        if (urlArray.length >= 7) {
            urlArray[5] = country;
            urlArray[6] = lang;
            if (urlArray[7].indexOf('#') > -1) {
                tempcustomerSet = urlArray[7].split('#');
                tempcustomerSet[0] = customerset;
                urlArray[7] = tempcustomerSet[0] + '/#' + tempcustomerSet[1];
            } else {
                urlArray[7] = customerset;
            }
        } else {
            urlArray.push(country);
            urlArray.push(lang);
            if (urlArray[4].indexOf('#') > -1) {
                tempcustomerSet = urlArray[4].split('#');
                urlArray[4] = tempcustomerSet[0];
                urlArray.push(customerset + '/#');
            } else {
                urlArray.push(customerset);
            }
        }
    }
    return urlArray;
}

function CountryClickEventBasedOnThreeSegmentedUrl(country, lang, customerset, urlArray) {
    var tempcustomerSet;
    if (urlArray[3].match(new RegExp("support", "gi"))) {
        if (urlArray.length >= 8) {
            urlArray[6] = country;
            urlArray[7] = lang;
            if (urlArray[8].indexOf('#') > -1) {
                tempcustomerSet = urlArray[8].split('#');
                tempcustomerSet[0] = customerset;
                urlArray[8] = tempcustomerSet[0] + '/#' + tempcustomerSet[1];
            } else {
                urlArray[8] = customerset;
            }
        } else {
            urlArray.push(country);
            urlArray.push(lang);
            if (urlArray[5].indexOf('#') > -1) {
                tempcustomerSet = urlArray[5].split('#');
                urlArray[5] = tempcustomerSet[0];
                urlArray.push(customerset + '/#');
            } else {
                urlArray.push(customerset);
            }
        }
    }
    return urlArray;
}

function CountryClickEventBasedOnCultureUrl(country, lang, urlArray) {
    var tempUrlSet;
    if (urlArray[3].match(new RegExp("support", "gi"))) {
        if (urlArray.length >= 5) {
            if (urlArray[5].indexOf('#') > -1) {
                tempUrlSet = urlArray[5].split('#');
                tempUrlSet[0] = lang + '-' + country;
                urlArray[5] = tempUrlSet[0] + '/#' + tempUrlSet[1];
            } else {
                urlArray[5] = lang + '-' + country;
            }
        } else {
            urlArray.push(lang + '-' + country);
        }
    }
    return urlArray;
}

function CountryClickEventBasedOnThreeSegmentedCultureUrl(country, lang, urlArray) {
    var tempUrlSet;
    if (urlArray[3].match(new RegExp("support", "gi"))) {
        if (urlArray.length >= 6) {
            if (urlArray[6].indexOf('#') > -1) {
                tempUrlSet = urlArray[6].split('#');
                tempUrlSet[0] = lang + '-' + country;
                urlArray[6] = tempUrlSet[0] + '/#' + tempUrlSet[1];
            } else {
                urlArray[6] = lang + '-' + country;
            }
        } else {
            urlArray.push(lang + '-' + country);
        }
    }
    return urlArray;
}


$(document).ready(function() {

    $("html").removeClass('pageFlicker');
    $('#mobile-new-search-link').on('click',
        function() {
            // mobile view search
            setTimeout(function() {
                Dell.Types.SupportHomepage.prototype.handleSearch(
                    Dell.Supportpage.Constants.Selectors.SEARCH_FORM_MOB,
                    Dell.Supportpage.Constants.Selectors.SEARCH_INPUT_MOB,
                    Dell.Supportpage.Constants.Selectors.SEARCH_SELECTION_MOB);
                Dell.Types.SupportHomepage.prototype.handleSearchAutoComplete(
                    Dell.Supportpage.Constants.Selectors.SEARCH_SELECTION_MOB,
                    Dell.Supportpage.Constants.Selectors.SEARCH_FORM_MOB,
                    Dell.Supportpage.Constants.Selectors.SEARCH_INPUT_MOB);
            },
                0);
        });

    function countryselectorLangu() {
        setTimeout(function() {
            if ($("#countryselectorLangu").length > 0 && $("#imgcountryselector").offset() != undefined) {
                $("#countryselectorLangu").offset({ left: $("#imgcountryselector").offset().left });
            }
        },
            0);
    }

    $(window).on('resize',
        function() {
            Dell.Types.SupportHomepage.prototype.signInMenu();
            Dell.Types.SupportHomepage.prototype.initializeUnifiedMastHead();
            countryselectorLangu();
        });
    countryselectorLangu();

    var isEsupportMh = Dell.OnlineUtils.AppSettings.MasthHead;
    if (isEsupportMh !== undefined && isEsupportMh !== null) {
        var signinUrl = Dell.OnlineUtils.AppSettings.SignInUrl;
        if (signinUrl === undefined || signinUrl === null) {
            if (window.accountInfoUrl !== undefined && window.accountInfoUrl !== '') {
                signinUrl = window.accountInfoUrl;
            }
        }
        $.ajax({
            type: 'GET',
            url: signinUrl,
            data: { 'callbackUrl': 'REPLACETHIS', 'doCacheUnauth': "1" },
            contentType: 'application/json',
            async: true,
            cache: false,
            success: function(result) {
                if (typeof (result) != 'undefined') {
                    setTimeout(function() {
                        try {
                            result = result.replace("REPLACETHIS", encodeURIComponent(window.location.href));
                            $(".sign-in-content-wrapper").html(result);
                            var divObjAiSignOut = document.getElementById('divAccountInfoSignOut');
                            if (divObjAiSignOut !== undefined && divObjAiSignOut !== null) {
                                var isEmc = divObjAiSignOut.getAttribute('data-isEmc') === 'True';
                                var isEmcSupportAccess = divObjAiSignOut.getAttribute('data-isEmcSupportAccess') ===
                                    'True';
                                if (isEmc && isEmcSupportAccess) {
                                    document.getElementById('accInfoBadge').setAttribute("fill", "#000055");
                                } else if (isEmc && !isEmcSupportAccess) {
                                    document.getElementById('accInfoBadge').setAttribute("fill", "#ffa500");
                                }
                                document.getElementById('dt-cir-check').style.display = 'block';
                            }
                            Dell.OnlineUtils.Auth = Dell.OnlineUtils.Auth || {};
                            var divObjAccountInfo = document.getElementById("divMastheadAccountInfoJSVariables");
                            if (divObjAccountInfo !== undefined && divObjAccountInfo !== null) {
                                Dell.OnlineUtils.AppSettings.Token = divObjAccountInfo.getAttribute("data-Token");
                                Dell.OnlineUtils.Auth = {
                                    LogoUrl: divObjAccountInfo.getAttribute("data-LogoUrl"),
                                    IsPremier: divObjAccountInfo.getAttribute("data-IsPremier"),
                                    IsChannelPartner: divObjAccountInfo.getAttribute("data-IsChannelPartner"),
                                    HeaderText: divObjAccountInfo.getAttribute("data-HeaderText")
                                }
                            }
                            $('.flyout-btn button.btn-default').removeClass('btn-default')
                                .addClass('btn-outline-primary');
                            var auth = Dell.OnlineUtils.Auth;
                            if (auth !== undefined && Object.keys(Dell.OnlineUtils.Auth).length > 0) {
                                if (auth.IsPremier.toLowerCase() === 'true') {
                                    $('.delllogo').attr('href', auth.LogoUrl);
                                    $(".tier1 li").each(function() {
                                        if ($(this).has(".tier2").length === 1) {
                                            return false;
                                        } else {
                                            $(this).find("a").attr('href', auth.LogoUrl);
                                        }
                                        return true;
                                    });
                                    $(".m-tier1 li").each(function() {
                                        if ($(this).hasClass("active")) {
                                            return false;
                                        } else {
                                            $(this).find("a").attr('href', auth.LogoUrl);
                                        }
                                        return true;
                                    });
                                }
                                if (auth.IsPremier.toLowerCase() === 'true' ||
                                    (auth.IsChannelPartner !== undefined &&
                                        auth.IsChannelPartner.toLowerCase() === 'true')) {
                                    $("#countryselector").html($("#countryselector .dropdown-toggle").html());
                                    $("#countryselector").find('div').addClass('pull-left');
                                    $("#countryselectorLangu").remove();
                                    $("#divFooterLinks").removeClass().addClass('col-sm-12 col-md-12');
                                }
                                $('#user_name').html(auth.HeaderText);
                            }
                            $('#signin-link').html($('#authheaderdiv').html());
                            $('.flyout-btn').click(function(e) {
                                e.preventDefault();
                                var url = $(this).attr('data-href')
                                    .replace("REPLACETHIS", encodeURIComponent(window.location.href));
                                SecureRedirectUrl(url);
                                return false;
                            });
                            //Search
                            Dell.Supportpage.Urls.Token = (Dell.OnlineUtils.AppSettings.Token);
                            $("a[id^='lwp_a']").each(function(i, elem) {
                                var elemid = $(elem).attr("id").split('_');
                                $(this).attr("href", '').addClass("dropdown-item").click(function(event) {
                                    event.preventDefault();
                                    footerUrlLwp(elemid[2], elemid[3], elemid[4]);
                                });
                            });
                            $("a[id^='lwp_b']").each(function(i, elem) {
                                var elemid = $(elem).attr("id").split('_');
                                $(this).click(function(event) {
                                    event.preventDefault();
                                    footerUrlLwp(elemid[2], elemid[3], elemid[4]);
                                });
                            });
                            $("a[id^='lwp_c']").each(function(i, elem) {
                                var elemid = $(elem).attr("id").split('_');
                                $(this).click(function(event) {
                                    event.preventDefault();
                                    footerUrlLwp(elemid[2], elemid[3], elemid[4]);
                                });
                            });
                        } catch (ex) {
                            console.error(ex);
                        }
                    },
                        0);
                }
            },
            failure: function(error) {
                console.error(error);
            }
        });
    }
});

function CheckAuthentication() {
    var isAuth = false;

    if (typeof (Dell) !== 'undefined' && typeof (Dell.Metrics) !== 'undefined' && typeof (Dell.Metrics.sc) !== 'undefined' && typeof (Dell.Metrics.sc.userrole) !== 'undefined') {
        if (Dell.Metrics.sc.userrole !== "") {
            isAuth = true;
        } else {
            isAuth = false;
        }
        LoadCoveoSearchSettings(isAuth);
    }
    else if (typeof (window.searchAuth) !== 'undefined') {
        if (window.searchAuth === 'False') {
            isAuth = false;
        }
        if (window.searchAuth === 'True') {
            isAuth = true;
        }
        LoadCoveoSearchSettings(isAuth);
    }
    else if (window.authenticateApiRoute != undefined && window.authenticateApiRoute != "") {
        $.ajax({
            type: 'GET',
            url: window.authenticateApiRoute,
            contentType: 'application/json',
            async: true,
            success: function (result) {
                LoadCoveoSearchSettings(result);
            },
            failure: function (error) {
                console.error("Error with Authentication API call : " + error);
                LoadCoveoSearchSettings(isAuth);
            }
        });
    }
}

function SetCoveoSearchSettings(config) {
    var parseObject = JSON.parse(config);
    Dell.OnlineUtils.AppSettings.Unified = parseObject;
    Dell.OnlineUtils.AppSettings.Token = Dell.OnlineUtils.AppSettings.Unified.CoveoToken;
    return parseObject;
}

function LoadCoveoSearchSettings(isAuthenticated) {

    var coveoCacheKey ='CoveoSearchSetting';
    if ((isAuthenticated != undefined || isAuthenticated != null) && !isAuthenticated) {
        if (localStorage.getItem("CoveoSearchSetting_A") != undefined && localStorage.getItem("CoveoSearchSetting_A") != null) {
            localStorage.removeItem("CoveoSearchSetting_A");
        }
        coveoCacheKey = "CoveoSearchSetting";
    }
    else {
        if (localStorage.getItem("CoveoSearchSetting") != undefined && localStorage.getItem("CoveoSearchSetting") != null) {
            localStorage.removeItem("CoveoSearchSetting");
        }
        coveoCacheKey = "CoveoSearchSetting_A";
    }

    var checkLocalStorage = (localStorage[coveoCacheKey] != undefined && localStorage[coveoCacheKey] != null);

    var validateLocalStorage = function () {
        var validToken = false, validLwp = false, tokenExpiry = false;
        if (checkLocalStorage) {
            var parseObject = SetCoveoSearchSettings(localStorage[coveoCacheKey]);
            searchApiPromise.resolve(true);
            if (parseObject.CoveoToken != undefined && parseObject.CoveoToken != null) {
                validToken = parseJwt(parseObject.CoveoToken);
            }
            if (parseObject.expTime != undefined && parseObject.expTime != null) {
                tokenExpiry = new Date() < parseObject.expTime;
            }
            if (parseObject.Lwp != undefined && parseObject.Lwp != null) {
                validLwp = parseObject.Lwp.country == Dell.OnlineUtils.Lwp.country && parseObject.Lwp.language == Dell.OnlineUtils.Lwp.language;
                if (!validLwp) {
                    Dell.OnlineUtils.AppSettings.Unified.Lwp = {
                        country: Dell.OnlineUtils.Lwp.country,
                        language: Dell.OnlineUtils.Lwp.language
                    };
                }
            }
            if (validLwp) {
                callWebWorker();
            }
            return !(validToken && validLwp && tokenExpiry);
        } else {
            return true;
        }
    }
    var searchApiUrl = window.searchApiRoute;
    try {
        if (!isAuthenticated) {
            searchApiUrl = window.searchApiRoute + "/unathuser/" + Dell.OnlineUtils.Lwp.country + "/" + Dell.OnlineUtils.Lwp.language;
        }
    }
    catch (e) {
    }
    if (searchApiUrl != undefined && searchApiUrl != "" && validateLocalStorage()) {
        $.ajax({
            type: 'GET',
            url: searchApiUrl,
            contentType: 'application/json',
            async: true,
            success: function (result) {
                Dell.OnlineUtils.AppSettings.Unified = {
                    DisplayUnifiedAutosuggestImage: result.SearchKeyValues.DisplayUnifiedAutosuggestImage,
                    MastheadUnifiedDellSearchUrl: result.SearchKeyValues.MastheadUnifiedDellSearchUrl,
                    IsMastheadUnifiedProductNameAutoSuggestEnabled: result.SearchKeyValues.IsMastheadUnifiedProductNameAutoSuggestEnabled,
                    MastheadUnifiedProductNameAutoSuggestMinLength: result.SearchKeyValues.MastheadUnifiedProductNameAutoSuggestMinLength,
                    IsTwoSectionUnifiedAutosuggestEnabled: result.SearchKeyValues.IsTwoSectionUnifiedAutosuggestEnabled === "1",
                    UnifiedDellResultTitle: result.SearchKeyValues.UnifiedDellResultTitle,
                    UnifiedCoveoResultTitle: result.SearchKeyValues.UnifiedCoveoResultTitle,
                    UnifiedViewAllFor: result.SearchKeyValues.UnifiedViewAllFor,
                    UnifiedDellProductRedirectUrl: result.SearchKeyValues.UnifiedDellProductRedirectUrl,
                    UnifiedEmcProductRedirectUrl: result.SearchKeyValues.UnifiedEmcProductRedirectUrl,
                    UnifiedIsRedirectEmcAssetsIps: result.SearchKeyValues.UnifiedIsRedirectEmcAssetsIps,
                    CoveoToken: (result.SearchKeyValues.CoveoToken === undefined || result.SearchKeyValues.CoveoToken == null || result.SearchKeyValues.CoveoToken.trim() == "") ? '{}' : result.SearchKeyValues.CoveoToken,
                    UnifiedCoveoMastheadAutoSuggestGeneralSearchApiUri: result.SearchKeyValues.UnifiedCoveoMastheadAutoSuggestGeneralSearchApiUri,
                    UnifiedValidateEntityApiUri: result.SearchKeyValues.UnifiedValidateEntityApiUri,
                    UnifiedisRedirectionMultipleMatch: result.SearchKeyValues.UnifiedisRedirectionMultipleMatch == "1",
                    UnifiedIsAssetSearchEnabled: result.SearchKeyValues.UnifiedIsAssetSearchEnabled == "1",
                    UnifiedIsSrSearchEnabled: result.SearchKeyValues.UnifiedIsSrSearchEnabled == "1",
                    UnifiedValidateEntityCheckRegEx: result.SearchKeyValues.UnifiedValidateEntityCheckRegEx,
                    UnifiedIsValidateEntityCheckEnabled: result.SearchKeyValues.UnifiedIsValidateEntityCheckEnabled,
                    UnifiedEmcProductRedirectIpsUrl: result.SearchKeyValues.UnifiedEmcProductRedirectIpsUrl,
                    UnifiedSearchRedirectUrl: result.SearchKeyValues.UnifiedSearchRedirectUrl,
                    UnifiedCoveoApiUrl: result.SearchKeyValues.UnifiedCoveoApiUrl,
                    expTime: new Date().getTime() + 7200000,
                    UnifiedAutosuggestJsUrl: result.SearchKeyValues.UnifiedAutosuggestJsUrl,
                    Lwp: {
                        country: Dell.OnlineUtils.Lwp.country,
                        language: Dell.OnlineUtils.Lwp.language,
                    }
                };
                localStorage[coveoCacheKey] = JSON.stringify(Dell.OnlineUtils.AppSettings.Unified);
                Dell.OnlineUtils.AppSettings.Token = Dell.OnlineUtils.AppSettings.Unified.CoveoToken;
                callWebWorker();
                searchApiPromise.resolve(true);
            },
            failure: function (error) {
                console.error("Error with Search API call : " + error);
            }
        });

    }
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    var parsedObject = JSON.parse(jsonPayload);
    if (new Date(parsedObject.exp) < new Date())
        return true;
    else
        return true;
};

function SecureRedirectUrl(url) {
    window.location.href = url;
}

Dell.Types.SupportHomepage.prototype.ProductSupportData = [];
Dell.Types.SupportHomepage.prototype.redirectProductSupportPage = function () {
    var arrdata = Dell.Types.SupportHomepage.prototype.ProductSupportData;
    if (arrdata.uri.length > 0) {
        SecureRedirectUrl(arrdata.uri);
    }
};

$.when(searchApiPromise.promise())
    .done(function(apiresponse) {
        //old Masthead
        $("#masthead-search, #masthead-search-mob").mouseover(function() {
            Dell.Types.SupportHomepage.prototype.initializeUnifiedMastHead();
            $('#masthead-search, #masthead-search-mob').unbind('mouseover');
        });

        //UMH Masthead
        $(".mh-search").mouseover(function() {
            Dell.Types.SupportHomepage.prototype.initializeUnifiedMastHead();
            $('.mh-search').unbind('mouseover');
        });
    }).fail(function() {
    }).always(function() {
    });