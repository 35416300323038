(function($) {
    $.extend({
        DellProductsJsStorage: {
            parse: function(products) {
                var productListForAutosuggest = new Array();
                var dellRedirectUrl = Dell.OnlineUtils.AppSettings.Unified.UnifiedDellProductRedirectUrl;
                var emcRedirectUrl = Dell.OnlineUtils.AppSettings.Unified.UnifiedEmcProductRedirectUrl;
                var emcAuthFormatUri = "";
                var useProdCode = emcRedirectUrl.indexOf("[integrated]") == -1 ? false : true;
                //correcting sitelocale 
                if (window.emcCultureCode == undefined || window.emcCultureCode == "")
                    emcRedirectUrl = emcRedirectUrl.replace("siteLocale=",
                        "siteLocale=" + Dell.OnlineUtils.Lwp.language + "_" + Dell.OnlineUtils.Lwp.country);
                else
                    emcRedirectUrl = emcRedirectUrl.replace("siteLocale=", "siteLocale=" + window.emcCultureCode);

                for (var i = 0, j = products.length; i < j; i++) {
                    productListForAutosuggest[productListForAutosuggest.length] = {
                        url: products[i].EMC == 0
                            ? dellRedirectUrl.replace('[productcode]', products[i].PC)
                            : GetEmcRedirectUrl(products[i]),
                        value: products[i].PN,
                        result: products[i].PN,
                        isDellProduct: true,
                        imageUrl: products[i].IU
                    };
                }

                function GetEmcRedirectUrl(product) {
                    var url = '';
                    if (Dell.OnlineUtils.AppSettings.Unified.UnifiedIsRedirectEmcAssetsIps == "True") {
                        url = Dell.OnlineUtils.AppSettings.Unified.UnifiedEmcProductRedirectIpsUrl.replace(
                            '[productcode]',
                            product.PC);
                    } else {
                        url = (!emcAuthFormatUri || emcAuthFormatUri == "" || !window.btoa)
                            ? emcRedirectUrl.replace('[productcode]', useProdCode ? product.PC : product.EPC)
                            : emcAuthFormatUri.replace('[REPLACEREPLAYSTATE]',
                                btoa(emcRedirectUrl.replace('[productcode]', useProdCode ? product.PC : product.EPC)));
                    }

                    return url;
                }

                return productListForAutosuggest;
            },

            search: function(currSearchString) {
                try {
                    if (currSearchString == "" || currSearchString == undefined || currSearchString == null)
                        return new Array();
                    //searchAutosuggestProductDetails is loaded from individual java script file from url
                    if (searchAutosuggestProductDetails != null && searchAutosuggestProductDetails != undefined) {

                        var productListForAutosuggest = searchAutosuggestProductDetails.filter(function(n, i) {
                            //this filter is same as of home application		
                            return n.PN != undefined && n.PN != "";
                        });

                        if (productListForAutosuggest != undefined && productListForAutosuggest.length > 0) {
                            var filtered = productListForAutosuggest.filter(function(n, i) {
                                return (RegExp(currSearchString)).test(n.PN.toLowerCase());
                            });

                            if (filtered != null && filtered != undefined && filtered.length > 0) {
                                var parsed = $.DellProductsJsStorage.parse(filtered);
                                parsed = parsed.sort(function(a, b) {
                                    var x = a.value.toLowerCase();
                                    var y = b.value.toLowerCase();
                                    if (x < y) {
                                        return -1;
                                    }
                                    if (x > y) {
                                        return 1;
                                    }
                                    return 0;
                                });

                                if (parsed != null && parsed != undefined && parsed.length > 0)
                                    return parsed;
                            }

                        }
                    }
                } catch (error) {
                }
                return new Array(); //return empty array if no result.
            }

        },
        Coveo: {
            parse: function(data) {
                var array = new Array();
                var filter = window.location.hash;
                if (filter.indexOf("&q") > 0) {
                    filter = filter.slice(filter.indexOf("sort"), filter.indexOf("&q"));
                } else {
                    filter = filter.slice(filter.indexOf("sort"), filter.length);
                }
                filter = filter.slice(filter.indexOf("sort"), filter.length);
                if (data !== null && data !== undefined) {
                    if (data.completions !== null && data.completions !== undefined && data.completions.length > 0) {
                        for (var i = 0; i < data.completions.length; i++) {
                            array[array.length] = {
                                url: Dell.OnlineUtils.AppSettings.Unified.UnifiedSearchRedirectUrl +
                                    '#q=' +
                                    encodeURIComponent(data.completions[i].expression) +
                                    '&' +
                                    filter,
                                value: data.completions[i].expression,
                                isDellProduct: false
                            };
                        }
                    }
                }
                return array;
            },

            search: function(searchText, token) {

                var returnValue = new Array();
                if (Dell.OnlineUtils.AppSettings.Unified.UnifiedCoveoApiUrl != undefined) {
                    var contentType = "application/x-www-form-urlencoded; charset=utf-8";
                    var pardata = {
                        term: encodeURIComponent(searchText),
                        q: searchText,
                        limit: 5,
                        count: 5
                    };

                    $.ajax({
                        dataType: 'json',
                        url: Dell.OnlineUtils.AppSettings.Unified.UnifiedCoveoApiUrl + '&language=' + Dell.OnlineUtils.Lwp.language + '&context=' + encodeURIComponent('{"lang":"' + Dell.OnlineUtils.Lwp.language + '","geo":"' + Dell.OnlineUtils.Lwp.country + '","product":""}'),
                        crossDomain: true,
                        type: 'GET',
                        cache: false,
                        contentType: contentType,
                        data: pardata,
                        async: false,
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader("Authorization", 'Bearer ' + token);
                        },
                        success: function (data) {
                            var array = $.Coveo.parse(data);
                            returnValue = array;
                        },
                        error: function (a, b, c) {

                        }
                    });
                }
                return returnValue;
            }
        }
    });
})(jQuery);
