var soitworker;

/*function getWebWorkerURL(url) {
    const content = `importScripts( "${url}" );`;
    return URL.createObjectURL(new Blob([content], { type: "text/javascript" }));
}*/
function startSoitWorker() {
    if (typeof (Worker) !== "undefined") {
        try {
            if (typeof (soitworker) === "undefined") {
                //const worker_as_url = getWebWorkerURL("https://www.dellcdn.com/support/staticcdn/worker/js/1.1/worker.min.js");
                soitworker = new Worker("https://www.dell.com/support/staticcdn/worker/js/1.1/worker.min.js");
                window.WebWorkerCompleted = false;
                soitworker.postMessage({ "args": [JSON.stringify(Dell)] });
                soitworker.onmessage = function (event) {
                    window.searchAutosuggestProductDetails = event.data;
                    window.WebWorkerCompleted = true;
                    if (typeof window.autoSuggestWWComplete === "function") {
                        var callbackfunc = window.autoSuggestWWComplete;
                        callbackfunc.call(this);
                    };
                    stopSoitWorker();
                };
                //URL.revokeObjectURL(worker_as_url);
            }
        } catch (e) {
            if (typeof window.autoSuggestLoadError === "function") {
                var callbackfunc = window.autoSuggestLoadError;
                callbackfunc.call(this, e);
            }
        }
    } else {
        console.log("No Web Worker");
    }
}

function stopSoitWorker() {
    soitworker.terminate();
    soitworker = undefined;
}

function callWebWorker() {
    if (typeof (Dell) !== 'undefined' &&
        typeof (Dell.OnlineUtils) !== 'undefined' &&
        typeof (Dell.OnlineUtils.AppSettings) !== 'undefined' &&
        typeof (Dell.OnlineUtils.AppSettings.Unified) !== 'undefined' &&
        typeof (Dell.OnlineUtils.AppSettings.Unified.UnifiedAutosuggestJsUrl) !== 'undefined' &&
        typeof (window.searchAutosuggestProductDetails) === 'undefined') {
        startSoitWorker();
    }
}

if (typeof (callWebWorker_event) === 'function') {
    callWebWorker_event();
}