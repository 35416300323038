(function () {
    $(document).ready(function () {
        function loadImg(tag, attr) {
            var len = document.querySelectorAll(tag + '[data-src]').length;
            for (var i = 0; i < len; i++) {
                (function () {
                    var img = document.querySelectorAll(tag + '[data-src]')[i];
                    img.setAttribute(attr, img.getAttribute('data-src'));
                    img.onload = function () {
                        img.removeAttribute('data-src');
                    };
                }());
            }
        }
        loadImg('img', 'src');
        loadImg('object', 'data');
    });
})();

function isWindows10s() {
    if (window.external && "getHostEnvironmentValue" in window.external) {
        var skuKey = "os-sku";
        var buildNumbers = ["178", "179"];
        var result = window.external.getHostEnvironmentValue(skuKey);
        if (result && (result.indexOf(buildNumbers[0]) !== -1 || result.indexOf(buildNumbers[1]) !== -1)) {
            var shorthandBrowserName = "10S";
            return true;
        }
    }
    return false;
}