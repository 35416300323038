(function (dell) {
    dell.Error = dell.Error || {};
    dell.Error.logerror = function (url, jqXhr, exception, errortextstatus) {
        var msg;
        if (jqXhr.status === 0) {
            msg = 'Not connect.\n Verify Network.';
        } else if (jqXhr.status === 404) {
            msg = 'Requested page not found.';
        } else if (jqXhr.status === 500) {
            msg = 'Internal Server Error.';
        } else if (exception === 'parsererror') {
            msg = 'Requested JSON parse failed.';
        } else if (exception === 'timeout') {
            msg = 'Time out error.';
        } else if (exception === 'abort') {
            msg = 'Ajax request aborted.';
        } else {
            msg = 'Uncaught Error.\n' + jqXhr.responseText;
        }
        console.error('Ajax load issue Url => ' + url + ' | status => ' + jqXHR.status + ' | Error => ' + exception + ' | Error Message => ' + errortextstatus + ' | Message => ' + msg);
    };
})(Dell = window.Dell || {});