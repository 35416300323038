Dell = window.Dell || {};
Dell.Types = Dell.Types || {};
Dell.OnlineUtils = Dell.OnlineUtils || {};

Dell.Types.SupportHomepage.prototype.handleCoveoSearchQuery = function (searchinput, filteredSearchString) {
    var inputData = Dell.Types.SupportHomepage.prototype.getValidateEntityApiInput(filteredSearchString);
    var request = $.ajax({
        url: Dell.OnlineUtils.AppSettings.Unified.UnifiedValidateEntityApiUri,
        method: "POST",
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(inputData)
    });
    request.done(function (response) {
        if (response) {
            try {
                if (response &&
                    response.IsSearchtextInKB) {
                    RedirctToNullResultPage();
                    return false;
                }
                else if (response &&
                    response.EntrySelectionType &&
                    response.EntrySelectionType !== '0' &&
                    response.LookupResults &&
                    response.LookupResults.length > 0) {
                    Dell.Types.SupportHomepage.prototype.handleRedirect(response.LookupResults, searchinput);
                }
                else {
                    if (typeof (Storage) !== "undefined") {
                        sessionStorage.IsEntityValidationPerformed = true;
                    }
                    Dell.Types.SupportHomepage.prototype.SetEntrySelectionContext(inputData, response);
                    Dell.Types.SupportHomepage.prototype.redirectToCoveoSearchPage(searchinput);
                }
            } catch (e) {
                console.error('Dell.Types.SupportHomepage.prototype.handleCoveoSearchQuery.Done failed with exp : ' +
                    e);
                Dell.Types.SupportHomepage.prototype.redirectToCoveoSearchPage(searchinput);
            }
        }
    });

    request.fail(function (jqXHR, textStatus) {
        console.error(
            "Request failed for Dell.Types.SupportHomepage.prototype.handleCoveoSearchQuery.ValidateEntrySelection: " +
            textStatus);
        Dell.Types.SupportHomepage.prototype.redirectToCoveoSearchPage(searchinput);
    });
};
function GetKbdocNullResultRedirectUri(uri) {
    var redirectUri = uri;
    if (Dell.OnlineUtils.Lwp.language !== undefined && Dell.OnlineUtils.Lwp.language !== null && Dell.OnlineUtils.Lwp.country !== undefined &&
        Dell.OnlineUtils.Lwp.country !== null) {
        redirectUri = uri.replace("lwp", Dell.OnlineUtils.Lwp.language + "-" + Dell.OnlineUtils.Lwp.country);
    }
    else {
        redirectUri = uri.replace("lwp/", "");
    }
    return redirectUri;
}

function SetNullResultForKB(searchtext) {
    var redirectUri = GetKbdocNullResultRedirectUri("/support/search/lwp/Search/SetNullResultForKB?SearchText=" + searchtext);
    $.ajax({
        dataType: 'json',
        url: redirectUri,
        type: 'GET', cache: false,
        success: function (data) {
            returnValue = data;
        },
        failure: function (data) {
            console.error("SetNullResultForKB : " + data.responseText);
        },
        error: function (data) {
            console.error("SetNullResultForKB : " + data.responseText);
        }
    });
}

function GetNullCahceRequestParams() {
    return {
        RedirectUri: GetKbdocNullResultRedirectUri("https://www.dell.com/support/kbdoc/lwp/000196860/service-tag-search-null-results"),
        IntervelValue: 10,
    }
}

function RedirctToNullResultPage() {
    var nullCacheParams = GetNullCahceRequestParams();
    setTimeout(function () { window.location.href = nullCacheParams.RedirectUri }, nullCacheParams.IntervelValue);
    return false;
}
var coveoNullResultCacheRegEx = '^(?=.{6,7}$)(?!dl)(?=.*[0-9])(?=.*[a-zA-Z]).*$';
function IsNullResultPatternMatch(searchText) {
    //var searchinput = Dell.Types.SupportHomepage.prototype.getSearchQuery(searchText);
    if (searchText.length > 0) {
        //var pattern = coveoNullResultCacheRegEx;
        //console.log("IsNullResultPatternMatch - Cache Pattern RegEx : " + pattern);
        var match = searchText.toLowerCase().match(coveoNullResultCacheRegEx);
        //console.log("IsNullResultPatternMatch - Cache Pattern Match : " + match);
        if (match != null && match.length > 0) {
            return true;
        }
    }
    return false;
}
Dell.Types.SupportHomepage.prototype.getValidateEntityApiInput = function (searchinput) {
    var serviceTag = IsValidateServiceTagInSearchText(searchinput.trim());
    var inputData = {
        Selection: serviceTag,
        appName: 'mastheadSearch',
        IsSevenCharTag: IsNullResultPatternMatch(serviceTag)
    };

    return inputData;
};

//Search text with single hyphen with valid(7 digits alphanumeric) service tag. Ex oma1-fgbm273
function IsValidateServiceTagInSearchText(searchText) {
    var regExpString7CharAlphanumeric = new RegExp("^(?=.{7}$)(?!dl)(?=.*[0-9])(?=.*[a-zA-Z]).*$", "i");
    var secondSearchText = searchText.split('-')[1];
    try {
        if (searchText && searchText.split('-').length - 1 > 0 && regExpString7CharAlphanumeric.test(secondSearchText)) {
            return secondSearchText;
        }
        return searchText;
    } catch (ex) {
        return searchText;
    }
}

Dell.Types.SupportHomepage.prototype.isScriptLoaded = function (url) {
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
        if (scripts[i].src == url) return true;
    }
    return false;
};

Dell.Types.SupportHomepage.prototype.handleRedirect = function (data, searchinput) {
    var url = data[0].TargetUrl;

    try {
        var redirectionType = "";
        switch (data[0].EsubType) {
            case 1:
                redirectionType = 'serviceTag';
                break;
            case 2:
                redirectionType = 'expressServiceCode';
                break;
            case 3:
                redirectionType = 'PSNT';
                break;
            case 4:
                redirectionType = 'serialNumber';
                break;
            case 5:
                redirectionType = 'serviceRequestNumber';
                break;
            case 6:
                redirectionType = 'Article';
                break;
            case 8:
                redirectionType = 'DriverId';
                break;
            case 9:
                redirectionType = 'Article';
                break;
            default:
                redirectionType = 'None';
                break;
        }

        redirectionType = redirectionType + ':' + Dell.Types.SupportHomepage.prototype.getSearchQuery(searchinput);
        if (redirectionType.indexOf("DriverId") > -1) {
            dellmetricsTrack_prop13_coveo_format(redirectionType);
        } else if (redirectionType.indexOf("Article") > -1) {
            dellmetricsTrack_prop13_coveo_format(redirectionType);
        }

        dellmetricsTrack_prop20_format("111.300.200.002", redirectionType);
    } catch (e) {
        console.error("Exception in Dell.Types.SupportHomepage.prototype.handleRedirect : " + url);
    }

    console.log("Dell.Types.SupportHomepage.prototype.handleRedirect : " + url);
    SecureRedirectUrl(url, false);
};

function dellmetricsTrack_prop13_coveo_format(value) {
    Dell.Metrics.sc.pagename = (Dell.Metrics.sc.country +
        '|' +
        Dell.Metrics.sc.language +
        '|' +
        Dell.Metrics.sc.segment +
        '|' +
        Dell.Metrics.sc.customerset +
        "|coveo|docsearchresults|" +
        '|' +
        value);
}

Dell.Types.SupportHomepage.prototype.redirectToCoveoSearchPage = function (searchinput) {
    Dell.Types.SupportHomepage.prototype.setSearchProgressAction(false, searchinput);
    var searchString = Dell.Types.SupportHomepage.prototype.getSearchQuery(searchinput);
    var searchUrl = Dell.OnlineUtils.AppSettings.Unified.UnifiedSearchRedirectUrl +
        "#q=" +
        encodeURIComponent(searchString) +
        (Dell.Supportpage.getNewHash().length > 0 ? Dell.Supportpage.getNewHash() : "");
    SecureRedirectUrl(searchUrl);
};

Dell.Types.SupportHomepage.prototype.getSearchQuery = function (searchInput) {
    try {
        if (typeof ($(searchInput).val()) != "undefined")
            return $(searchInput).val().trim().replace(/</g, '').replace(/>/g, '');
    } catch (ex) {
        console.error("Error while getting value" + ex);
    }
};
Dell.Types.SupportHomepage.prototype.SetEntrySelectionContext = function (req, data) {
    try {
        var key = "asset_v1_" + req.Selection.toLowerCase();
        if (typeof (Storage) !== "undefined") {
            sessionStorage.setItem(key, JSON.stringify(data));
        }
    }
    catch (e) {
        console.log("Exception in multiple match " + ex);
    }
}
Dell.Types.SupportHomepage.prototype.setSearchProgressAction = function (isProcessing, searchinput) {
    if (isProcessing &&
        (Dell.Supportpage.Constants.Selectors.SEARCH_INPUT_MOB === searchinput ||
            Dell.Supportpage.Constants.Selectors.SEARCH_INPUT == searchinput)) {
        if ($(Dell.Supportpage.Constants.Selectors.SEARCH_INPUT_MOB).is(":visible")) {
            $(Dell.Supportpage.Constants.Selectors.SEARCH_FORM_MOB).find('button').css("display", "none");
            $(Dell.Supportpage.Constants.Selectors.SEARCH_FORM_MOB).find('span').css("display", "");
        } else {
            $(Dell.Supportpage.Constants.Selectors.SEARCH_FORM).find('button').css("display", "none");
            $(Dell.Supportpage.Constants.Selectors.SEARCH_FORM).find('span').css("display", "");
        }
    } else {
        if ($(Dell.Supportpage.Constants.Selectors.SEARCH_INPUT_MOB).is(":visible")) {
            $(Dell.Supportpage.Constants.Selectors.SEARCH_FORM_MOB).find('button').css("display", "").next('span')
                .css("display", "none");
        } else {
            $(Dell.Supportpage.Constants.Selectors.SEARCH_FORM).find('button').css("display", "").next('span')
                .css("display", "none");
        }
    }
};

$(document).on("keydown",
    "#search-input, #search-input-mob," + Dell.Supportpage.Constants.Selectors.UNIFIED_MASTHEAD,
    (function (e) {
        if ((e.which == 38 || e.which == 40) &&
            $("#ui-active-menuitem") !== undefined &&
            $("#ui-active-menuitem")[0] !== undefined) {
            $("#search-input").val($("#ui-active-menuitem")[0].innerText);
        }
    }));

function dellmetricsTrack_prop20_format(appcode, tabname) {
    if (typeof (dellmetricsTrack) === "function") {
        if (dellmetricsTrack) {
            dellmetricsTrack(appcode, tabname);
        }
    }
}